"use client";

import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { TrackingContextProvider } from "../contexts/TrackingContext";
import { Suspense } from "react";

export const TrackingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <>
      <Suspense>
        <TrackingContextProvider>{children}</TrackingContextProvider>
      </Suspense>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_EMITEO_GTM ?? ""} />
      <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_EMITEO_GA ?? ""} />
    </>
  );
};

"use client";

import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { useEffect } from "react";

const rumConfig = {
  identityPoolId:
    process.env.NEXT_PUBLIC_AWS_RUM_APPLICATION_IDENTITY_POOL_ID || "",
  sessionSampleRate:
    process.env.NEXT_PUBLIC_AWS_RUM_APPLICATION_SESSION_SAMPLE_RATE || "0.1",
  endpoint:
    process.env.NEXT_PUBLIC_AWS_RUM_APPLICATION_ENDPOINT ||
    "https://dataplane.rum.eu-central-1.amazonaws.com",
  id: process.env.NEXT_PUBLIC_AWS_RUM_APPLICATION_ID || "",
  version: process.env.NEXT_PUBLIC_AWS_RUM_APPLICATION_VERSION || "1.0.0",
  region: process.env.NEXT_PUBLIC_AWS_RUM_APPLICATION_REGION || "eu-central-1",
};

export const ObservabilityProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useEffect(() => {
    try {
      const config: AwsRumConfig = {
        identityPoolId: rumConfig.identityPoolId,
        sessionSampleRate: Number(rumConfig.sessionSampleRate),
        endpoint: rumConfig.endpoint,
        telemetries: ["errors", "performance", "http"],
        allowCookies: true,
        enableXRay: true,
      };

      new AwsRum(rumConfig.id, rumConfig.version, rumConfig.region, config);
    } catch (error: unknown) {
      console.error(error);
    }
  }, []);

  return children;
};

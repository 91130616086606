"use client";

import { useSearchParams } from "next/navigation";
import { createContext } from "react";
import { useSession } from "../hooks/useSession";

export const TrackingContext = createContext(
  {} as {
    utm: {
      id?: string;
      source?: string;
      medium?: string;
      campaign?: string;
      term?: string;
      content?: string;
    };
  },
);

export const TrackingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const searchParams = useSearchParams();

  const [id] = useSession("utmId", searchParams.get("utm_id"));
  const [source] = useSession("utmSource", searchParams.get("utm_source"));
  const [medium] = useSession("utmMedium", searchParams.get("utm_medium"));
  const [campaign] = useSession(
    "utmCampaign",
    searchParams.get("utm_campaign"),
  );
  const [term] = useSession("utmTerm", searchParams.get("utm_term"));
  const [content] = useSession("utmContent", searchParams.get("utm_content"));

  const utm = {
    id,
    source,
    medium,
    campaign,
    term,
    content,
  };

  return (
    <TrackingContext.Provider value={{ utm }}>
      {children}
    </TrackingContext.Provider>
  );
};

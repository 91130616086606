"use client";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { plPL } from "@mui/x-date-pickers/locales";

export const DatePickerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale="pl"
      localeText={{
        ...plPL.components.MuiLocalizationProvider.defaultProps.localeText,
        ...{
          fieldYearPlaceholder: (params) => "R".repeat(params.digitAmount),
        },
      }}
    >
      {children}
    </LocalizationProvider>
  );
};

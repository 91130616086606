"use client";

import { useEffect, useState, useCallback } from "react";

export const useSession = (
  key: string,
  defaultValue: string | null,
): [string | undefined, (newValue: string) => void] => {
  const [value, setValue] = useState<string | undefined>(
    defaultValue ?? undefined,
  );

  const getSessionValue = (key: string) => {
    return sessionStorage.getItem(key);
  };

  const setSessionValue = useCallback(
    (newValue: string) => {
      setValue(newValue);
      sessionStorage.setItem(key, newValue);
    },
    [key],
  );

  useEffect(() => {
    const storedValue = getSessionValue(key);

    if (storedValue) {
      setValue(storedValue);
    }

    if (storedValue !== value && value) {
      setSessionValue(value);
    }
  }, [key, value, setSessionValue]);

  return [value, setSessionValue];
};
